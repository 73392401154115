import React, { useRef } from "react";
import { Navbar } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import logOutimg from "../../../assets/images/logout.svg";
import { lStorage } from "../../../components/utils/storage";
import adminLogo from "../../../assets/images/logo/admin-log.jpg";

const Header = () => {
  const adminDetails = lStorage.get("adminInfo");
  const ref = useRef(null);

  const logOut = () => {
    lStorage.remove("admSelectLocation");
    lStorage.remove("selectPOCLocation");
    localStorage.clear();
    navigate("/admin");
  };

  return (
    <>
      <header
        ref={ref}
        id="header"
        className="header-section sticky"
        style={{
          borderBottom: "1px solid #d9d9d9",
          height: "98px",
          paddingTop: "6px",
        }}
      >
        <div className="top-menu">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Navbar collapseOnSelect expand="lg" className="col-md-12 mb-2">
                  <Navbar.Brand>
                    <Link to="/admin/datatable/">
                      <img
                        src={adminLogo}
                        alt="Logo"
                        className="float-right"
                      />
                    </Link>
                  </Navbar.Brand>
                  <Navbar.Toggle />
                  <Navbar.Collapse>
                    {adminDetails &&  adminDetails?.super_admin == 1 && (
                    <>
                    <Link
                        to="/admin/Company-registration"
                        className=" nav-link pl-4"
                      >
                        Company Registration
                      </Link><Link
                        to="/admin/POC-Details"
                        className=" nav-link pl-4"
                      >
                          POC Details
                        </Link>
                        </>
                    )}
                    {adminDetails && (
                      <span style={{ marginLeft: "auto" }}>
                        <b>Logged In : </b>
                        {adminDetails && adminDetails?.mobile_number}
                        <span
                          title="Logout"
                          style={{
                            paddingLeft: "15px",
                            cursor: "pointer",
                          }}
                          type="button"
                          role="button"
                          tabIndex="0"
                          onKeyDown={() => logOut()}
                          onClick={() => logOut()}
                        >
                          {" "}
                          <img
                            className="img-fluid"
                            src={logOutimg}
                            width="20px"
                            alt="Clock logo"
                          />
                        </span>
                      </span>
                    )}
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
